.nav-mobile, .nav-mobile__overlay, .form-control, .btn-clean, .btn-submit, button, .btn-primary, .btn-secondary, .btn-tertiary {
  transition: all 0.5s ease-in-out;
}

.btn-primary, .btn-secondary, .btn-tertiary {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
* :focus {
  outline: none;
}

body {
  background-color: white;
}

a {
  color: inherit;
  font-size: inherit;
}
a:hover {
  color: #87878C;
  text-decoration: unset;
}

i,
strong,
h2 strong {
  font-size: inherit;
  color: inherit;
  font-size: inherit;
}

p img {
  max-width: 100% !important;
  height: auto !important;
  object-fit: cover;
}

ol,
ul {
  list-style: none;
  padding-left: unset;
  margin-bottom: unset;
}

button {
  border: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

h1 {
  font-size: 3.25rem;
  color: #333336;
  font-weight: bold;
}

h2 {
  font-size: 2.625rem;
}

h3,
h4 {
  font-size: 2.25rem;
}

h5 {
  font-size: 1.875rem;
}

h6 {
  font-size: 1.5rem;
}

.btn-primary {
  color: white;
  background-color: #87878C;
  font-size: 1rem;
  width: 109px;
  min-height: 41px;
  box-shadow: 0px 4px 4px #87878C;
}
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem #87878C;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: #87878C !important;
  background-color: white !important;
  border: 1px solid #87878C !important;
}

.btn-secondary {
  width: 100%;
  min-height: 41px;
  font-size: 1rem;
  color: #334668;
  background-color: #C4D5E8;
  border-color: transparent;
  padding: 8px 30px;
  text-decoration: none;
  border-radius: 10px;
  font-weight: 700;
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary:focus {
  color: #FFFFFF !important;
  background-color: #678AC8 !important;
  border-color: transparent;
}

.btn-tertiary {
  width: 100%;
  min-height: 41px;
  font-size: 1rem;
  color: #FFFFFF;
  background-color: #90ABDD;
  border-color: transparent;
  padding: 12px 30px;
  text-decoration: none;
  border-radius: 30px;
  max-width: max-content;
}
.btn-tertiary:hover, .btn-tertiary:focus, .btn-tertiary:active, .btn-tertiary:focus {
  color: #FFFFFF !important;
  background-color: #678AC8 !important;
  border-color: transparent;
}

.btn-clean {
  background-color: #333336;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 700;
}
.btn-clean:hover, .btn-clean:focus, .btn-clean:active, .btn-clean:focus {
  color: #FFFFFF;
  background-color: #87878C;
}

.btn-submit {
  background-color: #f5f5f5;
  color: #334668;
  font-size: 1rem;
  font-weight: 700;
  border: 1px solid #334668;
  padding: 8px 40px;
}
.btn-submit:hover, .btn-submit:focus, .btn-submit:active, .btn-submit:focus {
  box-shadow: 0 0 4px 1px #334668;
  color: #334668;
}

.btn-section-title {
  margin: 0 auto;
  width: max-content;
  min-height: 41px;
  font-size: 1.5rem;
  color: #334668;
  background-color: transparent;
  border: 1px solid #334668;
  padding: 10px 40px;
  text-decoration: none;
  border-radius: 20px;
  text-align: center;
}
@media (max-width: 599px) {
  .btn-section-title {
    font-size: 1.125rem;
  }
}

.form-group {
  margin-bottom: 10px;
}
.form-group label {
  color: #334668;
  margin-bottom: 6px;
}
.form-control {
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid #678AC8 !important;
  resize: none;
  padding: 10px;
}
.form-control::placeholder {
  /* WebKit browsers */
  color: #A2A9B0;
  padding: 12px;
  font-size: 15px;
}
.form-control::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #A2A9B0;
  padding: 12px;
  font-size: 15px;
}
.form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #A2A9B0;
  padding: 12px;
  font-size: 15px;
}
.form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #A2A9B0;
  padding: 12px;
  font-size: 15px;
}
.form-control:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #A2A9B0;
  padding: 12px;
  font-size: 15px;
}
.form-control:hover, .form-control:focus, .form-control:active, .form-control:focus {
  box-shadow: 0 0 5px 1px #334668;
}
.form select.form-control {
  color: #334668;
}

.input_invalid {
  border: 1px solid #ff0000;
}

.title::after {
  content: "";
  display: block;
  width: 48px;
  height: 3px;
  margin-top: 5px;
  left: 0;
  background-color: #334668;
}

.title-center {
  font-size: 1.875rem;
}
.title-center::after {
  content: "";
  display: block;
  width: 48px;
  height: 4px;
  margin: 0 auto;
  margin-top: 5px;
  left: 0;
  background-color: #334668;
}

.placeholder {
  background-color: #eee;
}
.placeholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.map .embed-responsive {
  max-height: 283px;
}

.toast-message, .toast-title {
  color: white;
}

.image img {
  max-width: 100%;
}
.image-style-side {
  float: left;
  margin-right: 1rem;
}

.nav__link {
  font-family: "Lato", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  color: #334668;
  border: 1px solid transparent;
}
.nav__link:hover {
  color: #678AC8;
  border: 1px solid #678AC8;
  border-radius: 20px;
}
@media (max-width: 990px) {
  .nav__link {
    color: #334668;
  }
  .nav__link:hover {
    color: #678AC8;
    border-color: transparent;
  }
}

.header__top {
  background-color: #334668;
  color: #FFFFFF;
}
.header__top__phone a {
  text-decoration: none;
  font-size: 1rem;
}
.header__top__socials {
  display: flex;
  align-items: center;
  gap: 10px;
}
.header__top__socials a {
  font-size: 1rem;
  text-decoration: none;
}

@media (max-width: 599px) {
  .header__nav {
    margin-right: 1.5rem;
  }
}

.nav {
  width: 100%;
}
.nav__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
@media (max-width: 990px) {
  .nav__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 4px;
  }
  .nav__list .nav__item {
    padding: 8px 0;
    border-bottom: 1px solid #e3e3e3;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  .nav__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 4px;
  }
  .nav__list .nav__item {
    padding: 8px 0;
    border-bottom: 1px solid #e3e3e3;
  }
}
.nav__link {
  padding: 8px 16px;
  text-decoration: none;
}

.nav-mobile {
  position: fixed;
  top: 0;
  left: -240px;
  z-index: 100;
  width: 240px;
  height: 100%;
  background-color: #FFFFFF;
}
.nav-mobile__overlay {
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.nav-mobile__close {
  text-align: right;
  font-size: 1.125rem;
  margin: 2rem 1rem 0 0;
  color: #334668;
  cursor: pointer;
}
.nav-mobile__button {
  width: 100%;
  font-size: 1.25rem;
  padding-left: 25px;
  font-weight: 600;
  color: #334668;
  cursor: pointer;
}
.nav-mobile .nav__list {
  align-items: initial;
  justify-content: initial;
}
@media (min-width: 1200px) {
  .nav-mobile {
    position: static;
    width: 100%;
    background-color: transparent;
  }
  .nav-mobile__button, .nav-mobile__close {
    display: none;
  }
}
.nav-mobile__active, .nav-mobile__active > .nav-mobile__overlay {
  left: 0;
}

.information {
  background-color: #FFFFFF;
  color: #334668;
}
@media (max-width: 990px) {
  .information .container .d-flex {
    flex-direction: column;
    align-items: start;
  }
}
.information__list {
  display: flex;
  flex-direction: column;
}
.information__list__item {
  margin-bottom: 0.5rem;
}
.information__list__item__box {
  display: flex;
  gap: 13px;
}
.information__list__item__box__icon {
  color: #FFFFFF;
  font-size: 1rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}
.information__list__item__box__icon i {
  padding: 12px;
  border-radius: 50%;
  background: linear-gradient(90deg, #334668 0%, #9AA6BB 100%);
}
.information__list__item__box__content__title {
  color: #334668;
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 0;
  text-align: start;
}
.information__list__item__box__content__description {
  font-size: 0.875rem;
  text-align: start;
  text-decoration: none;
}
.information__list__item__socials {
  display: flex;
  gap: 4px;
}
.information__list__item__socials a {
  font-size: 1.25rem;
  padding: 4px;
}
.information__link {
  font-size: 0.875rem;
  text-decoration: none;
}

.rights {
  align-items: center;
  padding: 0.5rem 0px;
  background-color: #334668;
}
.rights p {
  color: #FFFFFF;
  font-size: 1rem;
}
.rights p a {
  text-decoration: none;
}
@media (max-width: 599px) {
  .rights p {
    font-size: 0.875rem;
  }
}
@media (min-width: 1200px) {
  .rights {
    flex-direction: row;
  }
}
@media (max-width: 599px) {
  .rights {
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .info-container {
    flex-direction: column;
    align-items: center;
  }
}