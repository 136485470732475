@use "../abstracts" as *;
@import "../../variables";

.title {
    // margin-bottom: unset;

    &::after {
        content: "";
        display: block;
        width: 48px;
        height: 3px;
        // margin: 0 auto;
        margin-top: 5px;
        left: 0;

        background-color: color(secondary, 300);
    }
}

.title-center {
    // margin-bottom: unset;
    font-size: size(title, 200);

    &::after {
        content: "";
        display: block;
        width: 48px;
        height: 4px;
        margin: 0 auto;
        margin-top: 5px;
        left: 0;

        background-color: color(secondary, 300);
    }
}



.placeholder {
    background-color: color(placeholder);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.map {
    .embed-responsive {
        max-height: 283px;
    }
}
