@use "../abstracts" as * ;

.form {

    &-group {
        margin-bottom: 10px;

        label {
            color: color(secondary, 300);
            margin-bottom: 6px;
        }
    }

    &-control {
        @extend %transitionDefault;
        font-size: size(text, 300);
        border-radius: 6px;
        @include placeholder(#A2A9B0, 12px, 15px);
        border: 1px solid color(secondary, 200) !important;
        resize: none;
        padding: 10px;

        &:hover,
        &:focus,
        &:active,
        &:focus {
            box-shadow: 0 0 5px 1px color(secondary, 300);
        }
    }

    select.form-control {
        color: color(secondary, 300);
    }
}

.input_invalid {
    border: 1px solid color(error);
}
