@use "./functions" as *;

%transitionDefault {
    transition: all 0.5s ease-in-out;
}

%backgroundImagemDefault {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

%alignCenter {
    align-items: center;
    justify-content: center;
}

%buttonDefault {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // border: 1px solid color(primary) !important;
    // border-radius: 10px;
    @extend %transitionDefault;
}

%scroll-bar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
