@use "../abstracts" as *;
@import "../../variables";

.information {
    background-color: color(white, 100);
    color: color(secondary, 300);

    .container {
        .d-flex {
            @include for-phone-and-tablet {
                flex-direction: column;
                align-items: start;
            }
        }
    }
    &__list {
        display: flex;
        flex-direction: column;

        &__item {
            margin-bottom: 0.5rem;

            &__box {
                display: flex;
                gap: 13px;

                &__icon {
                    color: color(white, 100);
                    font-size: size(text);
                    margin-right: 0.5rem;
                    display: flex;
                    align-items: center;

                    i {
                        padding: 12px;
                        border-radius: 50%;
                        background: linear-gradient(90deg, #334668 0%, #9AA6BB 100%);
                    }
                }

                &__content {

                    &__title {
                        color: color(secondary, 300);
                        font-weight: 600;
                        font-size: size(text, 400);
                        margin-bottom: 0;
                        text-align: start;
                    }

                    &__description {
                        font-size: size(text, 200);
                        text-align: start;
                        text-decoration: none;
                    }

                }
            }

            &__socials {
                display: flex;
                gap: 4px;

                a {
                    font-size: size(text, 500);
                    padding: 4px;
                }
            }
        }
    }

    &__link {
        font-size: size(text, 200);
        text-decoration: none;
    }
}

.rights {
    align-items: center;
    padding: 0.5rem 0px;
    background-color: color(secondary, 300);

    & p {
        color: color(white, 100);
        font-size: size(text, 300);

        & a {
            text-decoration: none;
        }

        @include for-phone-only {
            font-size: size(text, 200);
        }
    }

    @include for-desktop-up {
        flex-direction: row;
    }

    @include for-phone-only {
        flex-direction: column;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .info-container {
        flex-direction: column;
        align-items: center;
    }
}
