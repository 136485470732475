@use "../abstracts" as *;

.btn-primary {
  @include button(primary, size(text), white, 109px, 41px);
  @extend %buttonDefault;

  &:hover,
  &:focus,
  &:active {
    color: color(primary) !important;
    background-color: white !important;
    border: 1px solid color(primary) !important;
  }
}

.btn-secondary {
  @extend %buttonDefault;
  width: 100%;
  min-height: 41px;
  font-size: size(text, 300);
  color: color(secondary, 300);
  background-color: color(secondary, 100);
  border-color: transparent;
  padding: 8px 30px;
  text-decoration: none;
  border-radius: 10px;
  font-weight: 700;

  &:hover,
  &:focus,
  &:active,
  &:focus {
    color: color(white, 100) !important;
    background-color: color(secondary, 200) !important;
    border-color: transparent;
  }
}

.btn-tertiary {
    @extend %buttonDefault;
    width: 100%;
    min-height: 41px;
    font-size: size(text, 300);
    color: color(white, 100);
    background-color: #90ABDD;
    border-color: transparent;
    padding: 12px 30px;
    text-decoration: none;
    border-radius: 30px;
    max-width: max-content;

    &:hover,
    &:focus,
    &:active,
    &:focus {
      color: color(white, 100) !important;
      background-color: color(secondary, 200) !important;
      border-color: transparent;
    }
}

.btn-clean {
  @extend %transitionDefault;
  background-color: color(primary, 300);
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 700;

  &:hover,
  &:focus,
  &:active,
  &:focus {
    color: color(white, 100);
    background-color: color(primary, 400);
  }
}

.btn-submit {
  @extend %transitionDefault;
  background-color: #f5f5f5;
  color: color(secondary, 300);
  font-size: size(text, 300);
  font-weight: 700;
  border: 1px solid color(secondary, 300);
  padding: 8px 40px;

  &:hover,
  &:focus,
  &:active,
  &:focus {
    box-shadow: 0 0 4px 1px color(secondary, 300);
    color: color(secondary, 300);
  }
}

.btn-section-title {
  // @extend %buttonDefault;
  margin: 0 auto;
  width: max-content;
  min-height: 41px;
  font-size: size(title, 100);
  color: color(secondary, 300);
  background-color: transparent;
  border: 1px solid color(secondary, 300);
  padding: 10px 40px;
  text-decoration: none;
  border-radius: 20px;
  text-align: center;

  @include for-phone-only {
    font-size: size(text, 400);
  }

  // &:hover,
  // &:focus,
  // &:active,
  // &:focus {
  //   color: white !important;
  //   background-color: color(secondary, 400) !important;
  //   border-color: transparent;
  // }
}
