@use "../abstracts" as *;
@import "../../variables";

%textStyle {
    font-family: $font-main;
    font-size: size(text, 400);
    font-weight: 400;
    font-style: normal;
    text-align: center;
    color: color(secondary, 300);
    border: 1px solid transparent;

    &:hover {
        color: color(secondary, 200);
        border: 1px solid color(secondary, 200);
        border-radius: 20px;
    }

    @include for-phone-and-tablet {
        color: color(secondary, 300);
        &:hover {
            color: color(secondary, 200);
            border-color: transparent;
        }
    }
}

.header {
    &__top {
        background-color: color(secondary, 300);
        color: color(white, 100);

        &__phone {
            a {
                text-decoration: none;
                font-size: size(text, 300);
            }
        }

        &__socials {
            display: flex;
            align-items: center;
            gap: 10px;

            a {
                font-size: size(text, 300);
                text-decoration: none;
            }
        }
    }
}

.header__nav {
    @include for-phone-only {
        margin-right: 1.5rem;
    }
}

.nav {
    width: 100%;

    &__list {
        @include flex(flex, row, center, space-evenly);

        @include for-phone-and-tablet {
            @include flex(flex, column, center, flex-start);

            padding-top: 4px;

            .nav__item {
                padding: 8px 0;
                border-bottom: 1px solid #e3e3e3;
            }
        }
        @include for-tablet-landscape-only {
            @include flex(flex, column, center, flex-start);

            padding-top: 4px;

            .nav__item {
                padding: 8px 0;
                border-bottom: 1px solid #e3e3e3;
            }
        }
    }

    &__link {
        @extend %textStyle;
        padding: 8px 16px;
        text-decoration: none;
    }
}

.nav-mobile {
    @extend %transitionDefault;

    position: fixed;
    top: 0;
    left: -240px;
    z-index: 100;
    width: 240px;
    height: 100%;
    background-color: color(white, 100);

    &__overlay {
        @extend %transitionDefault;

        position: fixed;
        top: 0;
        right: -100%;
        z-index: 99;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }

    &__close {
        text-align: right;
        font-size: size(text, 400);
        margin: 2rem 1rem 0 0;
        color: color(secondary, 300);
        cursor: pointer;
    }

    &__button {
        width: 100%;
        font-size: size(text, 500);
        padding-left: 25px;
        font-weight: 600;
        color: color(secondary, 300);
        cursor: pointer;
    }

    .nav__list {
        align-items: initial;
        justify-content: initial;
    }

    @include for-desktop-up {
        position: static;
        width: 100%;
        background-color: transparent;

        &__button,
        &__close {
            display: none;
        }
    }

    &__active,
    &__active > &__overlay {
        left: 0;
    }
}
